const USERS_PATHS = {
  CREATE_USER_FROM_ACCOUNT: (id) => `/users/add/account/${id}`,
  CONFIRM_ACCOUNT: (id) => `/users/confirm/account/${id}`,
  LOGIN_BY_TOKEN: `/auth/me`,
  RESET_PSWD : `/auth/forgot-password`
};



const ACCOUNT_PATHS = {
  ACCOUNT_BY_ID: (id) => `/accounts/${id}`,
  ACCOUNT_BY_USER_ID: (id) => `/accounts/user/${id}`,
  UPDATE_ACCOUNT: (id) => `/accounts/${id}`,
  ACCOUNTS: "/accounts",
  CLIENTS_ACCOUNTS: "/accounts/clients",
  PARTNERS_ACCOUNTS: "/accounts/pros",
  PARTNERS_INSCRIPTION_DEMANDES: "/accounts",
};

const ARTICLES_PATHS = {
  ARTICLES: "/articles",
  OWN_ARTICLES: "/articles/list",
  ARTICLE_SELECT_OPTIONS: "/articles/select/options",
  ARTICLES_SEARCH: "/articles/search",
  ARTICLES_HOME: "/articles/home",
  ARTICLE_BY_ID: (id) => `/articles/${id}`,
  UPDATE_ARTICLE: (id) => `/articles/${id}`,
  UPDATE_ARTICLE_STATUS: (id) => `/articles/status/${id}`,
};

const EVENTS_PATHS = {
  EVENTS: "/events",
  EVENT_SELECT_OPTIONS: "/events/select/options",
  EVENTS_SEARCH: "/events/search",
  EVENT_BY_ID: (id) => `/events/${id}`,
  UPDATE_EVENT: (id) => `/events/${id}`,
  UPDATE_EVENT_STATUS: (id) => `/events/status/${id}`,
};

const SERVICES_PATHS = {
  SERVICE_REQUESTS: "/serviceRequests",
  UPDATE_SERVICE_REQUESTS: (id) => `/serviceRequests/${id}`,
};

export const ROUTE_PATHS = {
  ...USERS_PATHS,
  ...ACCOUNT_PATHS,
  ...ARTICLES_PATHS,
  ...EVENTS_PATHS,
  ...SERVICES_PATHS,
};
