import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import StorageManeger from '../../helpers/storage.manager'
import { LOGIN_USER, LOGOUT_USER } from '../actions'
import { systemNotif } from '../notifications/actions'
import { actionsEnum, typesEnum } from '../notifications/enums'

import { loginUserSuccess, loginUserError } from './actions'

import { API_REQUESTS } from '../../API'

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword)
}

const logintAsync = async (email, password) =>
  await API_REQUESTS.LOGIN(email, password)

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user
  const { history } = payload
  try {
    const loginUser = yield call(logintAsync, email, password)
    const { token, user } = loginUser

    // token & refreshToken saved in localstorage only
    StorageManeger.saveAllObj([
      { key: 'user', value: user },
      { key: 'token', value: token },
    ])

    // push just user in store
    yield put(loginUserSuccess(user))
    history.push('/')
  } catch ({ status, errMessage }) {
    yield put(loginUserError())
    yield put(
      systemNotif(
        actionsEnum.PUSH,
        typesEnum.ERROR,
        errMessage,
        status === 401 ? 'Authentication Error' : 'Error',
      ),
    )
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout)
}

const logoutAsync = async (history) => {
  history.push('/')
}

function* logout({ payload }) {
  const { history } = payload
  try {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    yield call(logoutAsync, history)
  } catch (error) {
    // do somethin
  }
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)])
}
