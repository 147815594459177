/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

// export const loginPath = "/user/login";

export const loginPath = process.env.REACT_APP_QANOUNE_FRONT_LOGIN

export const LoginError = (error) =>
  process.env.REACT_APP_QANOUNE_FRONT_LOGIN.concat(
    `?status=${error.status}&error=${error.errMessage}`,
  )

export const QANOUNE_FRONT = process.env.REACT_APP_QANOUNE_FRONT

export const PROFESSIONS_LIST = ['Avocat', 'Comptable', 'Huissier']

export const PROFESSIONS_OPTIONS = [
  { label: 'Tout', value: 'all' },
  { label: 'Avocat', value: 'Avocat' },
  { label: 'Comptable', value: 'Comptable' },
  { label: 'Huissier', value: 'Huissier' },
]

export const PRO_PLANS_LIST = ['Plan Marhaba', 'Plan Oustad', 'Plan Suits']

export const PRO_PLANS_OPTIONS = [
  { label: 'Tout', value: 'all' },
  { label: 'Plan Marhaba', value: 'plan-marhaba' },
  { label: 'Plan Oustad', value: 'plan-oustad' },
  { label: 'Plan Suits', value: 'plan-suits' },
]

export const CLIENT_PLANS_LIST = [
  'Consultation écrite détaillée.',
  'Consultation téléphonique de 20 mn',
  'Plan Start-up',
  'Abonnement Entreprises (PME MPI TPE)',
  'Consultation écrite 1000 caractères.',
  'Plan Particulier',
  'L’aide juridictionnelle',
]

export const CLIENT_PLANS_OPTIONS = [
  { label: 'Tout', value: 'all' },
  { label: 'Abonnement Start-up', value: 'abonnement-start-up' },
  {
    label: 'Abonnement Entreprises (PME MPI TPE)',
    value: 'abonnement-entreprises-pme-mpi-tpe',
  },
  {
    label: 'Abonnement Particulier',
    value: 'abonnement-particulier',
  },
  { label: 'L’aide juridictionnelle', value: 'laide-juridictionnelle' },
  { label: 'E-Orientation', value: 'e-orientation' },
  { label: 'E-Consultation', value: 'e-consultation' },
  // {
  //   label: "Consultation écrite détaillée",
  //   value: "consultation-ecrite-detaillee",
  // },
  // {
  //   label: "Consultation téléphonique de 20 mn",
  //   value: "consultation-telephonique-de-20-mn",
  // },
  // {
  //   label: "Consultation écrite 1000 caractères",
  //   value: "consultation-ecrite-1000-caracteres",
  // },
]

export const CONTACT_REQUEST_STATUSES_LIST = [
  'completed',
  'pending',
  'canceled',
]

export const CONTACT_REQUEST_STATUSES_OPTIONS = [
  { label: 'Tout', value: 'all' },
  { value: 'pending', label: 'En attente' },
  { value: 'completed', label: 'Répondu' },
  { value: 'canceled', label: 'Ignoré' },
]

export const ARTICLE_STATUSES_LIST = [
  'IN REVIEW',
  'PUBLISHED',
  'ARCHIVED',
  'REJECTED',
  'All',
]

export const ARTICLE_STATUSES_OPTIONS = [
  { value: 'IN REVIEW', label: 'EN REVUE' },
  { value: 'PUBLISHED', label: 'PUBLIÉ' },
  { value: 'ARCHIVED', label: 'ARCHIVÉ' },
  { value: 'REJECTED', label: 'REJETÉ' },
  { label: 'Tout', value: 'all' },
]

export const EVENTS_STATUSES_OPTIONS = [
  { value: true, label: 'PUBLIÉ' },
  { value: false, label: 'ARCHIVÉ' },
  { value: undefined, label: 'Tout' },
]

export const ACCOUNT_TYPE = {
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  PRO: 'PRO',
}

export const ARTICLE_STATUSES = {
  PENDING: 'IN REVIEW',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  REJECTED: 'REJECTED',
}

export const DEFAULT_PROFESSIONS = [
  'avocat',
  'notaire',
  'comptable',
  'huissier',
]

export const EVENT_STATUSES = {
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
}

export const SKILLS_OPTIONS = (professionName = '') => {
  switch (professionName) {
    case 'Avocat':
      return [
        {
          value: 'droit-de-la-famille',
          label: 'Droit de la Famille/Statut personnel',
        },
        {
          value: 'droit-commercial',
          label: 'Droit commercial',
        },

        {
          value: 'droit-civil',
          label: 'Droit civil',
        },
        {
          value: 'droit-des-societes',
          label: 'Droit des sociétés',
        },
        {
          value: 'droit-de-la-grande-distribution',
          label: 'Droit de la Grande distribution',
        },
        {
          value: 'droit-penal',
          label: 'Droit pénal',
        },
        {
          value: 'droit-social',
          label: 'Droit Social',
        },
        {
          value: 'droit-des-contrats',
          label: 'Droit des contrats',
        },
        {
          value: 'droit-immobilier-et-des-successions',
          label: 'Droit immobilier/successions',
        },
        {
          value: 'droit-admin',
          label: 'Droit Administratif  ',
        },
        {
          value: 'droit-de-lenvironnement',
          label: 'Droit de l’environnement',
        },
        {
          value: 'droit-de-la-medecine-et-du-sport',
          label: 'Droit de la médecine et du sport',
        },
        {
          value: 'droit-du-dommage-corporel',
          label: 'Droit du dommage corporel',
        },
        {
          value: 'droit-des-etrangers-et-de-la-nationalite',
          label: 'Droit des étrangers et de la nationalité',
        },
        {
          value: 'droit-de-la-securite-sociale-protection-sociale',
          label: 'Droit de la sécurité sociale et de la protection sociale',
        },
        {
          value: 'droit-des-transports',
          label: 'Droit des transports',
        },
        {
          value: 'procedure-d-appel',
          label: 'Procédures d’Appel ',
        },
        {
          value: 'droit-de-la-concurrence',
          label: 'Droit de la concurrence',
        },
        {
          value: 'droit-de-la-consommation',
          label: 'Droit de la consommation',
        },
        {
          value: 'droit-des-marques',
          label: 'Droit des marques',
        },
        {
          value: 'droit-des-tic',
          label: 'Droit des TIC',
        },
        {
          value: 'droit-de-nouvelles-technologies-startup',
          label: 'Droit et nouvelles technologies/Start-up',
        },
      ]

    case 'Comptable':
      return [
        {
          value: 'expert-comptable',
          label: 'Expert comptable',
        },
        {
          value: 'commissaire-au-compte',
          label: 'Commissaire au compte',
        },
      ]

    case 'Notaire':
      return []

    case 'Huissier':
      return []

    default:
      return []
  }
}

export const GET_SKILLS = (professionName, skills) =>
  (skills || []).map((skill) => {
    return SKILLS_OPTIONS(professionName).find(
      (option) => option.value === (skill?.name || skill?.value || skill),
    )
  })

export const DEFAULT_GENDERS = ['Homme', 'Femme']

export const defaultMenuType = 'menu-default'

export const DefaultAvatar = '/assets/img/avatar.jpg'

export const DefaultArticleIMG = '/assets/img/avatar.jpg'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = 'fr'
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'fr', name: 'Français', direction: 'ltr' },
]

export const servicePath = 'https://api.coloredstrategies.com'

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false
export const defaultColor = 'light.blue'
export const defaultDirection = 'ltr'
export const isDarkSwitchActive = true
export const themeColorStorageKey = '__theme_color'
export const themeRadiusStorageKey = '__theme_radius'
export const isDemo = false
