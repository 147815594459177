import { customPrivateAxiosInst, customPublicAxiosInst } from "./axios";

const login = async (email, password) => {
  const QanounApi = customPublicAxiosInst();

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.post("/auth/login", {
    email,
    password,
  });

  return response.data;
};

const loginByToken = async () => {
  const QanounApi = customPublicAxiosInst();

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.get("/auth/me");

  return response.data;
};

const reset_pwd = async (path, body = {}) => {
  const QanounApi = customPublicAxiosInst();

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.post(path, body);

  return response.data;
};

const create_json = async (path, body = {}) => {
  const QanounApi = customPrivateAxiosInst();

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.post(path, body);

  return response.data;
};

const create_urlencoded = async (path, body = {}) => {
  const QanounApi = customPrivateAxiosInst("application/x-www-form-urlencoded");

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.post(path, body);

  return response.data;
};

const fetch = async (path, query) => {
  const QanounApi = customPrivateAxiosInst("application/x-www-form-urlencoded");

  // Errors will be catched in the errorHandler middleware added within axios configs
  const response = await QanounApi.get(path.concat(objectToQuery(query)));

  return response.data;
};

const update_json = async (path, body = {}) => {
  const QanounApi = customPrivateAxiosInst();

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.put(path, body);

  return response.data;
};

const update_urlencoded = async (path, body = {}) => {
  const QanounApi = customPrivateAxiosInst("application/x-www-form-urlencoded");

  // the axios errors will be catched in the middleware added with axios config
  const response = await QanounApi.put(path, body);

  return response.data;
};

const objectToQuery = (object = {}) => {
  const params = Object.keys(object).map((key) =>
    object[key] !== null && object[key] !== undefined
      ? `${key}=${object[key]}`
      : ""
  );

  return !!params.length ? "?".concat(params.join("&")) : "";
};

export const API_REQUESTS = {
  LOGIN: login,
  CREATE: create_urlencoded,
  CEATE_JSON: create_json,
  FETCH: fetch,
  UPDATE_URLENCODED: update_urlencoded,
  UPDATE_JSON: update_json,
  LOGIN_BY_TOKEN: loginByToken,
  RESET_PWD: reset_pwd,
};
