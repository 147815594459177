import axios from 'axios'
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
import StorageManeger from '../helpers/storage.manager'

const getBasURL = (publicRoute = false) => {
  return publicRoute
    ? process.env.REACT_APP_PUBLIC_API_URL
    : process.env.REACT_APP_API_URL
}

const errorHandler = (err) => {
  let status = 421 // connection lost
  let errMessage = 'Connection lost, Please check your internet connection'

  if (err.response) {
    status = err.response.status
    errMessage = err.response.data || 'Some thing went wrong'

    if (errMessage.fr && errMessage.en) {
      errMessage = errMessage.fr
    }
  }

  // eslint-disable-next-line no-throw-literal
  throw {
    status,
    errMessage,
  }
}

const customAxiosInst = (contentType, publicRoute = false) => {
  const user = StorageManeger.getObj('user', true)
  const { identificator } = user || {}

  const token = StorageManeger.getObj('token')
  const authorization = `Bearer ${token}`

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': contentType || 'application/json',
    authorization,
    identificator,
  }

  const fetcher = axios.create({
    baseURL: getBasURL(publicRoute),
    validateStatus: (status) => {
      return status >= 200 && status < 400 // default
    },
    headers,
  })

  // if (autoRefreshToken)
  //   createAuthRefreshInterceptor(fetcher, refreshAuthLogic);

  fetcher.interceptors.response.use((res) => res, errorHandler)

  return fetcher
}

export const customPrivateAxiosInst = (contentType) =>
  customAxiosInst(contentType, false)
export const customPublicAxiosInst = (contentType) =>
  customAxiosInst(contentType, true)
